<template>
  <div class="forget-pwd">
    <!--<div class="find-pwd-panel">-->
    <h1 class="title">忘记密码</h1>
    <div class="description">请通过注册手机号重置密码</div>

    <a-form
      :form="form"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 12 }"
      autoComplete="off"
    >
      <a-form-item label="注册手机号">
        <a-input
          size="large"
          placeholder="请输入手机号"
          v-decorator.trim="['phone', validatorRules.phone]"
        />
      </a-form-item>
      <a-form-item label="账号新密码">
        <a-input
          size="large"
          type="password"
          placeholder="请输入6-18位登录密码"
          autocomplete="new-password"
          v-decorator.trim="['password', validatorRules.password]"
        />
      </a-form-item>
      <a-form-item label="确认新密码">
        <a-input
          size="large"
          type="password"
          placeholder="请再次输入新密码"
          autocomplete="new-password"
          v-decorator.trim="['confirmPwd', validatorRules.confirmPwd]"
        />
      </a-form-item>
      <a-form-item label="手机验证码">
        <a-row type="flex">
          <a-col span="15">
            <a-input
              size="large"
              class="verify-code-input"
              placeholder="请输入短信验证码"
              v-decorator.trim="['smsCode', validatorRules.smsCode]"
            />
          </a-col>
          <a-col span="9">
            <a-button
              size="large"
              class="verify-code-button"
              type="primary"
              ghost
              block
              @click="getSmsCode"
              :disabled="sendSmsCode"
            >
              <span v-if="!sendSmsCode">获取验证码</span>
              <span v-else>重新获取({{ smsCountDown }})s</span>
            </a-button></a-col
          >
        </a-row>
      </a-form-item>

      <a-row>
        <!--<a-col :span="8"></a-col>-->
        <a-col :span="12" offset="6">
          <a-button
            :loading="loading"
            style="width: 100%"
            size="large"
            type="primary"
            shape="round"
            class="submit-btn"
            @click="handleSubmit"
            >确认修改</a-button
          >
        </a-col>
        <!--<a-col :span="8"></a-col>-->
      </a-row>
    </a-form>
    <!--</div>-->
  </div>
</template>

<script>
import { Input, message, Form, Row, Col, Button } from "ant-design-vue";
import { smsCode, forgetPwd } from "@/request/user";
import md5 from "md5";
import { smsCodeRule } from "@/config/rules";
import { validatePwd } from "../../config/rules";

export default {
  name: "FindPwd",
  components: {
    [Input.name]: Input,
    [Form.name]: Form,
    [Form.Item.name]: Form.Item,
    [Row.name]: Row,
    [Col.name]: Col,
    [Button.name]: Button,
  },
  data() {
    return {
      active: "phone",
      password: "",
      imgContent: `/backend/login/code?${Math.random()}`,
      jsessionid2: "",
      loading: false,
      smsCountDown: 59,
      sendSmsCode: false,
      sendSmsTimer: null,
      form: this.$form.createForm(this),
      validatorRules: {
        phone: {
          rules: [
            { required: true, message: "请输入您的手机号码" },
            {
              pattern: /^[1]([3-9])[0-9]{9}$/,
              message: "请输入正确的手机号码",
            },
          ],
        },
        imageCode: {
          rules: [{ required: true, message: "请输入图片验证码" }],
        },
        smsCode: {
          rules: [
            { required: true, message: "请输入验证码" },
            { pattern: smsCodeRule, message: "请输入正确的验证码" },
          ],
        },
        password: {
          rules: [
            { required: true, message: "请输入您的新密码" },
            { validator: validatePwd },
          ],
        },
        confirmPwd: {
          rules: [
            { required: true, message: "请确认您的密码" },
            { validator: this.handleConfirmPwd },
          ],
        },
      },
    };
  },
  created() {},
  methods: {
    switchActive(mode) {
      this.active = mode;
      this.form.resetFields();
      this.changeImg();
    },
    changeImg() {
      this.imgContent = `/backend/login/code?${Math.random()}`;
    },
    getSmsCode() {
      let phoneNum = this.form.getFieldValue("phone");
      let reg = /^[1]([3-9])[0-9]{9}$/;
      if (!phoneNum) {
        message.error("请输入手机号码");
      } else if (!reg.test(phoneNum)) {
        message.error("请输入正确的手机号码");
      } else {
        let params = {
          phone: phoneNum,
        };
        smsCode(params).then(() => {
          message.success("验证码已发送");
          this.sendSmsCode = true;
          this.sendSmsTimer = setInterval(() => {
            this.smsCountDown--;
            if (this.smsCountDown <= 0) {
              this.sendSmsCode = false;
              this.smsCountDown = 59;
              clearInterval(this.sendSmsTimer);
            }
          }, 1000);
        });
      }
    },
    // validatePwd(rule, value, callback) {
    //   this.password = value;
    //   let reg =
    //     /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{8,}$/;
    //   if (!value) {
    //     callback();
    //   } else if (value.length < 8 || value.length > 16) {
    //     callback("密码长度为8-16位");
    //   } else if (!reg.test(value)) {
    //     callback("密码必须包含数字、大小写字母、特殊符号中的三种");
    //   } else {
    //     callback();
    //   }
    // },
    handleConfirmPwd(rule, value, callback) {
      const password = this.form.getFieldValue("password");
      if (!value) {
        callback();
      } else if (password && password !== value) {
        callback("两次输入密码不一致");
      } else {
        callback();
      }
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true;
          console.log(values);
          let params = {
            phone: values.phone,
            password: md5(values.password.trim()),
            smsCode: values.smsCode.trim(),
          };
          forgetPwd(params)
            .then(() => {
              this.loading = false;
              message.success("修改成功，请登录");
              this.form.resetFields();
              this.$router.push("/login");
            })
            .catch(() => {
              this.loading = false;
              this.changeImg();
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/variants.scss";

.forget-pwd {
  width: 1200px;
  height: 870px;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 0 auto;
  padding: 120px 0;

  .title {
    font-size: $font-size-card-title;
    font-weight: bold;
    margin-bottom: 18px;
  }

  .title,
  .description {
    color: $primary-text-color;
    text-align: center;
  }

  .description {
    font-size: $font-size-description;
    margin-bottom: 80px;
  }

  .ant-form {
    width: 640px;
    margin: 0 auto;
  }

  .verify-code-input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }

  .verify-code-button {
    font-size: 14px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    height: 40px;

    &[disabled="disabled"] {
      opacity: 0.5;
    }
  }
}
//.find-pwd {
//  width: 100%;
//  height: 100%;
//  padding-top: 46px;
//  padding-bottom: 40px;
//  margin-bottom: 120px;
//
//  .find-pwd-panel {
//    width: 1200px;
//    margin: 0 auto;
//    background-color: #ffffff;
//    padding: 80px 0 100px 0;
//    position: relative;
//    h1.title {
//      text-align: center;
//      color: #333333;
//      font-size: 24px;
//      font-weight: bold;
//    }
//    .under-line {
//      width: 342px;
//      height: 15px;
//      margin: 0 auto 88px auto;
//      img {
//        width: 342px;
//        height: 15px;
//      }
//    }
//
//    ul {
//      list-style: none;
//      display: flex;
//      margin: 0;
//      padding: 0;
//      position: absolute;
//      top: 165px;
//      left: 50%;
//      transform: translateX(-50%);
//      li {
//        color: #333333;
//        font-size: 16px;
//        margin: 0 15px;
//        cursor: pointer;
//        position: relative;
//        &.active {
//          color: @primary-color;
//          &:after {
//            content: "";
//            width: 60px;
//            height: 4px;
//            border-radius: 4px;
//            background-color: @primary-color;
//            position: absolute;
//            bottom: -14px;
//            left: 50%;
//            margin-left: -30px;
//          }
//        }
//      }
//    }
//
//    /deep/ form {
//      label {
//        font-size: 16px;
//        color: #666666;
//      }
//      .ant-input::placeholder {
//        color: #999999;
//        font-size: 14px;
//      }
//
//      .verify-img {
//        width: 100%;
//        height: 40px;
//        overflow: hidden;
//        position: relative;
//        img {
//          height: 100%;
//          height: 40px;
//          object-fit: cover;
//          position: absolute;
//          left: 50%;
//          top: 50%;
//          transform: translate(-50%, -50%);
//        }
//      }
//
//      .change {
//        width: 100%;
//        height: 40px;
//        line-height: 40px;
//        text-align: center;
//        span {
//          color: @primary-color;
//          font-size: 14px;
//          cursor: pointer;
//        }
//      }
//
//      .verify-code-input {
//        width: 62.5%;
//        border-top-right-radius: 0;
//        border-bottom-right-radius: 0;
//      }
//
//      .verify-code-button {
//        width: 37.5%;
//        border-top-left-radius: 0;
//        border-bottom-left-radius: 0;
//        margin-left: -1px;
//        top: -0.5px;
//        span {
//          font-size: 14px;
//        }
//      }
//
//      .pwd-strength {
//        position: absolute;
//        top: -10px;
//        right: -230px;
//      }
//
//      .submit-btn {
//        margin-top: 30px;
//        font-size: 18px;
//        height: 44px;
//        border-radius: 44px;
//      }
//    }
//  }
//}
</style>
